import { getElementsGroupById } from "@/helpers/utils";

export const quizOutcome = (formElements, pages) => {
  const elementsGroupById = getElementsGroupById(formElements);

  const thankYouPages = pages.filter((page) => page.type === "thank-you");

  thankYouPages.forEach((page) => {
    const outcome = page.pageStyling.outcome || {};
    let outcomeScore = page.pageStyling.outcomeScore || 0;

    Object.keys(outcome).forEach((fieldId) => {
      const element = elementsGroupById[fieldId];
      if (!element || !element.value) return;

      const options = outcome[fieldId];
      const selectedOptions =
        typeof element.value === "string" ? [element.value] : element.value;

      selectedOptions.forEach((option) => {
        const op = options.find((o) => o === option);
        if (op) {
          outcomeScore++;
        }
      });
    });

    page.pageStyling.outcomeScore = outcomeScore;
  });

  const finalThankyouPage = thankYouPages.reduce(
    (maxScorePage, currentPage) => {
      const currentPageOutcomeScore = currentPage.pageStyling.outcomeScore;
      const maxScorePageOutcomeScore = maxScorePage.pageStyling.outcomeScore;

      return maxScorePageOutcomeScore > currentPageOutcomeScore
        ? maxScorePage
        : currentPage;
    },
    { pageStyling: { outcomeScore: 0 } },
  );

  return finalThankyouPage.pageStyling.outcomeScore > 0
    ? finalThankyouPage
    : null;
};
