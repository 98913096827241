import { v4 as uuid } from "uuid";
import escape from "lodash/escape";
import trim from "lodash/trim";
import isEmpty from "lodash/isEmpty";
import debounce from "lodash/debounce";

export function awaitableDebounce(func, wait) {
  const debounced = debounce((resolve, reject, ...args) => {
    try {
      const result = func(...args);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  }, wait);

  return (...args) =>
    new Promise((resolve, reject) => {
      debounced(resolve, reject, ...args);
    });
}

export const getFieldId = (value) => {
  value = value || "";

  return value.startsWith("variable_")
    ? value.split("_")[1]
    : value.split("_")[0];
};

export const getVariableById = (variables, id) => {
  return variables.find((variable) => variable.uuid === id);
};

export const getVariablesGroupByUuid = (variables) => {
  return variables.reduce((acc, variable) => {
    if (!acc[variable.uuid]) {
      acc[variable.uuid] = [];
    }
    acc[variable.uuid].push(variable);
    return acc;
  }, {});
};

export const addAlpha = (color, opacity) => {
  let _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color.substr(0, 7) + _opacity.toString(16).toUpperCase();
};

export const reorderArray = (arr, dropResult) => {
  const { removedIndex, addedIndex, payload } = dropResult;
  if (removedIndex === null && addedIndex === null) return;

  let itemToAdd = payload;
  if (removedIndex !== null) {
    itemToAdd = arr.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    arr.splice(addedIndex, 0, itemToAdd);
  }
};

export const executeScript = (scriptContent) => {
  return new Promise((resolve, reject) => {
    // Remove <script> tags if present
    scriptContent = scriptContent.replace(/<\/?script>/gi, "");

    const script = document.createElement("script");
    script.textContent = scriptContent;

    // Set up onload and onerror handlers
    script.onload = () => {
      // NOTE: commenting this out
      // as there may be scripts that do api calls etc so it may be removed abruptly
      // I don't think there's any harm in letting these scripts hang on
      // if someone compliants we'll see
      // document.body.removeChild(script);
      // resolve();
    };

    script.onerror = () => {
      document.body.removeChild(script);
      reject(new Error("Script execution failed"));
    };

    // Append the script to start execution
    document.body.appendChild(script);
  });
};

export const addScriptToPage = (url = null, code = null) => {
  return new Promise((resolve) => {
    let script = document.createElement("script");

    script.onload = () => {
      resolve();
    };

    if (url) {
      script.setAttribute("src", url);
    } else if (code) {
      script.textContent = code;
    }

    document.head.appendChild(script);
  });
};

export const addLinkToPage = (href, rel = "stylesheet") => {
  return new Promise((resolve) => {
    let link = document.createElement("link");

    link.onload = () => {
      resolve();
    };

    link.setAttribute("href", href);
    link.setAttribute("rel", rel);

    document.head.appendChild(link);
  });
};

export const cloneJSON = (obj) => JSON.parse(JSON.stringify(obj));

const componentToHex = (c) => {
  const hex = c.toString(16).toUpperCase();
  return hex.length === 1 ? "0" + hex : hex;
};

export const rgbaToHex = (r, g, b) => {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b) + "FF";
};

export const readableLabel = (label) => {
  label = label || "";
  if (label.includes("_") && !label.includes("___")) {
    label = label.split("_")[1];
  }
  const regex = /@\{(.*?)\}/;
  let matches = label.match(regex);
  while (matches?.length > 0) {
    label = label.replace(matches[0], "______");
    matches = label.match(regex);
  }
  return label;
};

export const transformTextToLinks = (text = "") => {
  if (typeof text !== "string") return "";

  const urlPattern = /https?:\/\/[^\s]+/g;
  return text.replace(
    urlPattern,
    (url) => `<a href="${url}" target="_blank">${url}</a>`,
  );
};

export const isFormEmbeddedInIframe = () => {
  return window.self !== window.top;
};

export const hasStrongBlackContrast = (hexColor, threshold = 20) => {
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);

  const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;

  const contrastBlack = (luminance + 0.05) / 0.05;
  const contrastWhite = 1 / (luminance + 0.05);

  return contrastBlack > contrastWhite && contrastBlack >= threshold;
};

export const findMatchForFormElement = (items, targetId) => {
  for (const item of items) {
    if (item.id === targetId) {
      return item;
    } else if (item.children) {
      const childMatch = findMatchForFormElement(item.children, targetId);
      if (childMatch) {
        return childMatch;
      }
    }
  }
  return null;
};

// Function to find nested question for children like Address.
// In Address component, its sub-fields like City/State/Zipcode are nested children inside of children
// This function help finding those children and construct the questions used for Information recall feature
export const getNestedQuestions = (element) => {
  const nestedQuestions = [];

  for (const child of element.children) {
    const question = {
      label: child.label,
      value: `{${child.id}}`,
    };

    if (child.children) {
      nestedQuestions.push(...getNestedQuestions(child));
    } else {
      nestedQuestions.push(question);
    }
  }

  return nestedQuestions;
};

export const convertLabelToHTML = (label = "", items, variables) => {
  const regex = /@\{(.*?)\}/g;
  const matches = label.match(regex);

  if (!matches) {
    return label;
  }

  let parsedInput = label;

  matches.forEach((match) => {
    let fieldId = trim(match, "@{}");

    // Check if the ID is already wrapped in a data-id attribute
    const isIdAlreadyWrapped = new RegExp(`data-id="@{${fieldId}}"`).test(
      parsedInput,
    );

    if (isIdAlreadyWrapped) return;

    // If fieldId is variable Id i.e(variable_da15a3d9-4fc5-445f-b8f3-b479a214c) then extract id from it
    const isVariable = fieldId.startsWith("variable_");
    if (isVariable) {
      fieldId = fieldId.split("variable_")[1];
    }

    const label = getValueOrLabelByFieldId({
      id: fieldId,
      items,
      variables,
      outputType: "only-label",
    });
    const html = `<span data-type="mention" class="mention" contenteditable="false" data-id="@{${fieldId}}" data-label="${label}">${label}</span>`;

    // Creating a new regex to replace the specific match
    const matchRegex = new RegExp(`(?<!data-id="@{${fieldId}}")${match}`, "g");

    // Replacing the specific match with the replacement
    parsedInput = parsedInput.replace(matchRegex, html);
  });

  return parsedInput;
};

export const getValueOrLabelByFieldId = ({
  id,
  items,
  variables,
  outputType = "value-or-label", // (1: "value-or-label" [default- used in builder], 2: "only-value" [used in survey], 3: "only-label" [used in question input])
}) => {
  const variable = variables.find((variable) => variable.uuid === id);
  if (variable) {
    return outputType === "only-label" ? variable.name : variable.value;
  }

  // Recursive function to find match for nested children inside a children, For Example: Address
  const match = findMatchForFormElement(items, id);

  if (!match) {
    console.log(`No match found for ID: ${id}`);
    return "";
  }

  let label = readableLabel(match.label) || match.fixedName;
  let value = match.value || "";

  if (match.group === "choice") {
    if (value instanceof Array) {
      value = value.map((item) => {
        const option = match.options.find((option) => option.id === item);

        return option?.label || item;
      });
    } else {
      value =
        match.options.find((option) => option.id === value)?.label || value;
    }
  } else if (match.type === "repeat-field") {
    label += " (Rows)"; // Ex: Repeater Field (Rows)
    value = match.rows.length;
  }

  switch (outputType) {
    case "only-label":
      value = label;
      break;
    case "only-value":
      value = escape(value);
      break;
    default: //"value-or-label"
      value = escape(value?.toString() || label);
      break;
  }

  return value;
};

export const isIosDevice = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // Check if the user agent contains "iPhone", "iPad", or "iPod"
  return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
};

export const getNestedElementLabelFromId = (id, children) => {
  for (let child of children) {
    if (!isEmpty(child.children)) {
      return getNestedElementLabelFromId(id, child.children);
    } else if (id === child.id) {
      return child.label;
    }
  }
  return null;
};

export const getElementLabelFromId = (id, elements) => {
  let label = id;
  for (const el of elements) {
    if (!isEmpty(el.children)) {
      label = getNestedElementLabelFromId(id, el.children) || id;
    } else if (el.id === id) {
      label = el.label || id;
      break;
    }
  }
  return readableLabel(label);
};

export function findItemByPropertyValue({ items, property, value, nestedKey }) {
  for (const item of items) {
    if (item[property] === value) {
      return item;
    }

    if (item[nestedKey]) {
      const nestedItem = findItemByPropertyValue({
        items: item[nestedKey],
        property,
        value,
        nestedKey,
      });

      if (nestedItem) {
        return nestedItem;
      }
    }
  }
  return null;
}

export function getAllFields(item) {
  let fields = [];

  function traverse(children) {
    children.forEach((field) => {
      fields.push(field);
      if (field.children) {
        traverse(field.children);
      }
    });
  }

  traverse(item.children);

  return fields;
}

export function getRandomElementFromArray(elements) {
  const randomIndex = Math.floor(Math.random() * elements.length);

  return elements[randomIndex];
}

export function getElementsGroupById(elements) {
  return elements.reduce((acc, element) => {
    acc[element.id] = element;

    if (element.children) {
      groupChildElementsById(element.children, acc);
    }
    return acc;
  }, {});
}

export function groupChildElementsById(elements, obj) {
  elements.reduce((obj, element) => {
    if (element.children) {
      groupChildElementsById(element.children, obj);
    } else {
      obj[element.id] = element;
    }
    return obj;
  }, obj);
}

export function buildElement(element, pageId) {
  const el = { ...element, page: pageId };
  setId(el);

  // add id also to children if they are available
  if (el.children) {
    el.children = el.children.map((c) => {
      return buildElement(c, pageId);
    });
  } else if (el.components) {
    el.components = el.components.map((c) => {
      return buildElement(c, pageId);
    });
  } else if ((el.group === "choice" || el.type === "ranking") && el.options) {
    el.options.forEach((option) => {
      setId(option);
    });
  } else if (el.type === "matrix") {
    el.rows.forEach((row) => {
      setId(row);
    });

    el.columns.forEach((column) => {
      setId(column);
    });
  }

  return el;
}

export function setId(object) {
  object.id = uuid();
}

export function rgbaToSolidRgb(rgbaHex, backgroundHex = "#FFFFFF") {
  // Helper function to parse hex to RGB
  const parseHex = (hex) => {
    // if the value is not present just return back the same things without any processing
    if (!hex) return hex;

    const cleanHex = hex.replace(/^#/, "");
    return {
      r: parseInt(cleanHex.slice(0, 2), 16),
      g: parseInt(cleanHex.slice(2, 4), 16),
      b: parseInt(cleanHex.slice(4, 6), 16),
    };
  };

  // Helper function to convert RGB to hex
  const rgbToHex = (r, g, b) => {
    const toHex = (c) => {
      const hex = c.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    };
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  };

  // Remove the '#' if present and ensure valid length
  rgbaHex = rgbaHex.replace(/^#/, "");
  if (rgbaHex.length == 6) {
    // if it's not an alpha one and normal just return as is
    return `#${rgbaHex}`;
  } else if (rgbaHex.length !== 8) {
    throw new Error("Invalid RGBA hex code. It should be 8 characters long.");
  }

  // Parse RGBA and background colors
  const rgba = parseHex(rgbaHex);
  const bgColor = parseHex(backgroundHex);
  const alpha = parseInt(rgbaHex.slice(6, 8), 16) / 255;

  // Blend function
  const blend = (fgColor, bgColor, alpha) =>
    Math.round(fgColor * alpha + bgColor * (1 - alpha));

  // Blend colors
  const solidR = blend(rgba.r, bgColor.r, alpha);
  const solidG = blend(rgba.g, bgColor.g, alpha);
  const solidB = blend(rgba.b, bgColor.b, alpha);

  return rgbToHex(solidR, solidG, solidB);
}

export function getVisibleColor(colorHex, backgroundHex) {
  // Helper function to convert hex to RGB
  const hexToRgb = (hex) => {
    const rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return rgb
      ? {
          r: parseInt(rgb[1], 16),
          g: parseInt(rgb[2], 16),
          b: parseInt(rgb[3], 16),
        }
      : null;
  };

  // Helper function to convert RGB to hex
  const rgbToHex = (r, g, b) =>
    "#" +
    [r, g, b]
      .map((x) => {
        const hex = x.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      })
      .join("");

  // Helper function to calculate luminance
  const getLuminance = (r, g, b) => {
    const a = [r, g, b].map((v) => {
      v /= 255;
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  };

  // Helper function to calculate contrast ratio
  const getContrastRatio = (lum1, lum2) => {
    const lightest = Math.max(lum1, lum2);
    const darkest = Math.min(lum1, lum2);
    return (lightest + 0.05) / (darkest + 0.05);
  };

  // Convert input colors to RGB
  const color = hexToRgb(colorHex);
  const bgColor = hexToRgb(backgroundHex);

  // Calculate luminance and contrast
  const colorLum = getLuminance(color.r, color.g, color.b);
  const bgLum = getLuminance(bgColor.r, bgColor.g, bgColor.b);
  const contrast = getContrastRatio(colorLum, bgLum);

  // If contrast is already good, return original color
  if (contrast >= 1.2) {
    return colorHex;
  }

  // Function to adjust hue
  const adjustHue = (r, g, b, degree) => {
    const [h, s, l] = rgbToHsl(r, g, b);
    return hslToRgb((h + degree) % 360, s, l);
  };

  // Helper function to convert RGB to HSL
  const rgbToHsl = (r, g, b) => {
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h,
      s,
      l = (max + min) / 2;

    if (max === min) {
      h = s = 0;
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }
    return [h * 360, s, l];
  };

  // Helper function to convert HSL to RGB
  const hslToRgb = (h, s, l) => {
    h /= 360;
    let r, g, b;
    if (s === 0) {
      r = g = b = l;
    } else {
      const hue2rgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }
    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
  };

  // Try complementary color first
  let [r, g, b] = adjustHue(color.r, color.g, color.b, 180);
  let newColor = { r, g, b };
  let newLum = getLuminance(r, g, b);
  let newContrast = getContrastRatio(newLum, bgLum);

  // If complementary color doesn't work, try adjusting lightness
  if (newContrast < 4.5) {
    const [h, s, l] = rgbToHsl(r, g, b);
    const newL = bgLum > 0.5 ? Math.max(0, l - 0.3) : Math.min(1, l + 0.3);
    [r, g, b] = hslToRgb(h, s, newL);
    newColor = { r, g, b };
    newLum = getLuminance(r, g, b);
    newContrast = getContrastRatio(newLum, bgLum);
  }

  // If we still don't have good contrast, try adjusting hue and lightness
  if (newContrast < 4.5) {
    for (let i = 30; i < 360; i += 30) {
      [r, g, b] = adjustHue(color.r, color.g, color.b, i);
      const [h, s, l] = rgbToHsl(r, g, b);
      const newL = bgLum > 0.5 ? Math.max(0, l - 0.3) : Math.min(1, l + 0.3);
      [r, g, b] = hslToRgb(h, s, newL);
      newColor = { r, g, b };
      newLum = getLuminance(r, g, b);
      newContrast = getContrastRatio(newLum, bgLum);
      if (newContrast >= 4.5) break;
    }
  }

  return rgbToHex(newColor.r, newColor.g, newColor.b);
}

export function getInitials(name) {
  const words = name.trim().split(/\s+/);

  // One word
  if (words.length === 1) {
    return words[0][0].toUpperCase();
  }

  // Multiple words
  if (words.length > 1) {
    const firstInitial = words[0][0].toUpperCase();
    const lastInitial = words[words.length - 1][0].toUpperCase();

    return firstInitial + lastInitial;
  }

  return "";
}

export function isValidEmail(email) {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return emailRegex.test(email);
}

export function formatNumber(number, decimals = 2) {
  number = Number(number);
  if (isNaN(number)) {
    return "";
  }

  return Number.isInteger(number) ? number : Number(number.toFixed(decimals));
}
