import { get, post, put, destroy } from "./_utils";

export const getAllFormPrefills = (formId) => {
  return get(`/forms/${formId}/form_prefills.json`);
};

export const getFormPrefill = (formId, uuid) => {
  return get(`/forms/${formId}/form_prefills/${uuid}.json`);
};

export const createFormPrefill = (formId, data) => {
  return post(`/forms/${formId}/form_prefills.json`, data);
};

export const updateFormPrefill = (formId, prefillId, data) => {
  return put(`/forms/${formId}/form_prefills/${prefillId}.json`, data);
};

export const deleteFormPrefill = (formId, prefillId) => {
  return destroy(`/forms/${formId}/form_prefills/${prefillId}.json`);
};
